import React from 'react'
import { Button, Icon, Item, Label } from 'semantic-ui-react'
import { ItemDescription, ItemHeader } from '../Elements'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const ListItem = props => {
  
  const { heroImage, nameOfOffer, numberOfDays, numberOfSpaces, price, shortDescription, uid, dataWyjazdu } = props.p

  return (
    <Item>
      <Item.Image>
        <Img fixed={ heroImage.fixed } />
      </Item.Image>
      <Item.Content>
        <ItemHeader>{ nameOfOffer }</ItemHeader>
        <Item.Meta>
          <Label as='a' color='yellow'>{`${price + ' PLN'}`}</Label>
        </Item.Meta>
        <ItemDescription>
          <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
        </ItemDescription>
        <Item.Extra >
          <Label as='a' color='teal'>
            <Icon name='users' />
            Ilość miejsc:  
            <Label.Detail>{ numberOfSpaces }</Label.Detail>
          </Label>
          <Label as='a' color='teal'>
            <Icon name='clock outline' />
            Dni: 
            <Label.Detail>{ numberOfDays }</Label.Detail>
          </Label>
          <Label as='a' color='teal'>
            <Icon name='calendar alternate outline' />
            Data wyjazdu: 
            <Label.Detail>{ dataWyjazdu }</Label.Detail>
          </Label>
          <Link to={`/oferty/${uid}`}>
            <Button primary floated='right'>
              Zobacz Ofertę
              <Icon name='right chevron' />
            </Button>
          </Link>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}

export default ListItem