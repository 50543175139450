import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

import { Dropdown, Form, Grid, Item, ItemMeta, Message, Segment } from 'semantic-ui-react'

import { Header } from '../components/Elements'
import HeroSmall from '../components/HeroSmall'

import ContentWrapper from '../components/Elements/ContentWrapper'
import ListItem from '../components/ListItem'
import CTASection from '../components/CTA Section'

const basicSort = (a, b) => {
  return a.key - b.key
}

const OfferPage = ({ data, location }) => {
  const offersFromCMS = data.allDatoCmsOffer.edges.map(({node}) => ({
    ...node
  }))
  const [offers, setOffers] = useState(offersFromCMS)
  const [filterOffers, setFilterOffers] = useState(offers)
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [numGuests, setNumGuests] = useState([])
  const [numDays, setNumDays] = useState([])
  const [filtered, setFiltered] = useState(false)
  const [filterDays, setFilterDays] = useState('') 
  const [filterSpaces, setFilterSpaces] = useState('')
  const [testit, settestit] = useState(false)

  const selectOffer = useRef(null)
  const selectSpaces = useRef(null)
  const selectDays = useRef(null)

  useEffect(() => {
    generateLists(offers)
    if (location.state !== null) {
      setCategory(location.state.source)
    }
  }, [filtered])

  useEffect(() => {
    setCategory(location.state.source)
    selectOffer.current.setValue(location.state.source)
  }, [location.state])

  useEffect(() => {
    offerFilter()
  }, [category, filterSpaces, filterDays])

  const clearFilter = (e) => {
    setFilterOffers(offers)
    setFiltered(false)
    setCategory('')
    setFilterSpaces('')
    setFilterDays('')
    selectOffer.current.setValue('')
    selectSpaces.current.setValue('')
    selectDays.current.setValue('')
  }

  const generateLists = (offerList) => {

    offerList.map(( item ) => {
      item.categories.map((catItem) => {
        let tempCategory = { key: catItem.name, text: catItem.name, value: catItem.name }
        
        if (!categories.some(e => e.key === catItem.name)) {
          categories.push(tempCategory)
        }

      })

      let tempNumGuests = { key: item.numberOfSpaces, text: item.numberOfSpaces, value: item.numberOfSpaces }
      let tempNumDays = { key: item.numberOfDays, text: item.numberOfDays, value: item.numberOfDays }

      if (!numGuests.some(e => e.key === item.numberOfSpaces)) {
        numGuests.push(tempNumGuests)
      }
      
      if (!numDays.some(e => e.key === item.numberOfDays)) {
        numDays.push(tempNumDays)
      }

    })

    setCategories(categories.sort(basicSort))
    setNumGuests(numGuests.sort(basicSort))
    setNumDays(numDays.sort(basicSort))

  }

  const offerFilter = () => {

    var filterConditionDays = filterDays // selectDays.current.state.value;
    var filterConditionCategories = category //selectOffer.current.state.value;
    var filterConditionSpaces = filterSpaces // selectSpaces.current.state.value;
    let theFilter = []
    // A function which returns a function to be used for filtering.
    var includeDays = (includeDays) =>
      ({numberOfDays}) => numberOfDays === includeDays;
    // Another function which returns a function to also be used for filtering.
    var includeCategories = (includeCategories) =>
      ({categories}) => categories.some(item => {
        if (location.state.source !== undefined) { // || testit) {
          return includeCategories.includes(item.name)
        } else {
          return false
        }
      });
      
    var includeSpaces = (includeSpaces) =>
      ({numberOfSpaces}) => numberOfSpaces === includeSpaces;

    // This function takes any number of functions, and returns a function.
    // It returns a function that will "AND" together their return values of all the original functions
    var and = (...funcs) => (...innerArgs) => funcs.every(func => func(...innerArgs));

    // Create a filter which includes the type `filterConditionType`
    var daysFilter = includeDays(filterConditionDays);
    var categoriesFilter = includeCategories(filterConditionCategories);
    var spacesFilter = includeSpaces(filterConditionSpaces);

    if (filterConditionDays !== '') theFilter = [...theFilter, daysFilter]
    if (filterConditionCategories !== '' && location.state.source !== undefined) theFilter = [...theFilter, categoriesFilter]
    if (filterConditionSpaces !== '') theFilter = [...theFilter, spacesFilter]

    // Now do that actual filtering of the array.
    // var filteredArray = location.state.source !== undefined ? offers.filter(and(...theFilter)) : offers;
    var filteredArray = offers.filter(and(...theFilter))

    // And print it
    console.log("\x1b[35m%s\x1b[0m", `offerFilter`, filteredArray)
    setFilterOffers(filteredArray)

  }

  const handleChange = (e, { name, value }) => { 
    settestit(true)
    switch (name) {
      case `category`:
        setCategory(value)
        break;
    
      case `guests`:
        setFilterSpaces(value)
        break;
    
      case `days`:
        setFilterDays(value)
        break;
    
      default:
        break;
    }
  }

  return (
    <Layout>
      <SEO title='Oferty' />
      <HeroSmall data={ data.datoCmsHeadersSection } />
      <Grid centered stackable>
        <Grid.Row columns={2} textAlign='left'>
          <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
            <Segment basic style={{ marginTop: '4rem', backgroundColor: 'rgba(3,160,227,0.7)', borderRadius: '5px'}}>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Rodzaj Oferty</label>
                    <Dropdown ref={selectOffer} clearable options={categories} selection selectOnBlur={false} name='category' placeholder='Rodzaj Oferty' onChange={handleChange} defaultValue={category} />
                  </Form.Field>
                  <Form.Field>
                    <label>Ilość Miejsc</label>
                    <Dropdown ref={selectSpaces} clearable options={numGuests} selection selectOnBlur={false} name='guests' placeholder='Ilość Miejsc' onChange={handleChange}/>
                  </Form.Field>
                  <Form.Field>
                    <label>Ilość Dni</label>
                    <Dropdown ref={selectDays} clearable options={numDays} selection selectOnBlur={false} name='days' placeholder='Ilość Dni' onChange={handleChange}/>
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Button color='yellow' onClick={offerFilter}>Szukaj</Form.Button>
                  <Form.Button color='yellow' name='all' onClick={clearFilter}>Wszystkie Oferty</Form.Button>
                </Form.Group>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <ContentWrapper>
          <Grid centered >
            <Grid.Row >
              <Grid.Column>
                <Item.Group divided relaxed>
                  { filterOffers.map(( item, index ) => {
                    return (
                      <ListItem key={ index } p={ item } />
                    )
                  })}
                </Item.Group>
                <Message negative hidden={filterOffers.length > 0 ? true : false }>
                  <Message.Header>Przepraszamy, brak ofert z wybranymi filtrami</Message.Header>
                  <p>Prosimy ponownie wybrać</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ContentWrapper>
        <div style={{ minHeight: '60vh'}}></div>
      </Grid>
      <CTASection />
    </Layout>
  )
}

export default OfferPage

export const query = graphql`
  query FilterTest {
    allDatoCmsOffer {
      edges {
        node {
          uid
          nameOfOffer
          categories {
            name
          }
          shortDescription
          dataWyjazdu
          numberOfDays
          numberOfSpaces
          price
          insideImage {
            fixed(width: 175, height: 175) {
              ...GatsbyDatoCmsFixed
            }
          }
          insideDescriptionNode {
            childMarkdownRemark {
              html
            }
          }
          longDescriptionNode {
            childMarkdownRemark {
              html
            }
          }
          featuresListNode {
            childMarkdownRemark {
              html
            }
          }
          textDepositNode {
            childMarkdownRemark {
              html
            }
          }
          textFinePrintNode {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fixed(width: 175, height: 175) {
              ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 300, maxHeight: 300) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsHeadersSection(name: { eq: "oferty" }) {
      id
      name
      header
      heroImage {
        fixed(width: 900, height: 460) {
        ...GatsbyDatoCmsFixed
        }
        fluid(maxWidth: 1200, maxHeight: 460) {
        ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`